body, html { 
  font-family: 'STHeiti';
}

.custom-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -2px 4px rgba(0, 0, 0, 0.1);
}

@media print {
  .print-canvas {
    break-inside: avoid;
  }
  .print-section {
    display: block;
  }

  .non-print-section {
    display: none;
  }

  @page {
    size: auto;
    margin: 0;
  }
}
